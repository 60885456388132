/* THEPHOTOS, THEVIDEOS, THESHOWS */

.media-list {
  list-style-type: none;
  text-align: center;
  padding: 10px 0;
  position: relative;

  > .media-entry {
    width: 290px;
    min-height: 218px;
    border-radius: 6px;
    margin: 0 5px 10px 5px;
    position: relative;
    display: inline-block;
    overflow: hidden;

    &.meta {
      min-height: 436px;
      border-radius: 6px 6px 0 0;
    }

    header {
      background: #e5e5e5;
      width: 290px;
      height: 218px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      cursor: pointer;

      .no-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        color:white;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        &.thumb-static {
          z-index: 15;
        }
        &.thumb-anim, &.thumb-permanent {
          z-index: 10;
        }
      }

      &:hover img {
        &.thumb-static {
          z-index: 10;
        }
        &.thumb-anim, &.thumb-permanent {
          z-index: 15;
        }
      }
    }

    footer.control-buttons {
      width:100%;
      height: 56px;
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      position: absolute;
      bottom: 0;
      z-index: 15;

      .control-button {
        display: block;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border: none;
        border-radius: 18px;
        font-size: 18px;
        color: #444;
        background-color: #fff;
        cursor: pointer;
        line-height: 36px;

        &.remove-block {
          background-color: #ff4141;
          color: #fff;
        }
      }
    }

    footer.meta {
      padding: 15px;
      position: relative;
      top: 218px;
      height: 218px;
      border-radius: 0 0 6px 6px;
      background-color: var(--fanclub-footer_meta-bg_color, rgba(0, 0, 0, 0.5)) ;

      .additional {
        text-align: left;
      }
      .price {
        cursor: pointer;
        text-align: right;
      }
      .title {
        font-weight: bolder;
        max-height: 2em;
        text-overflow: ellipsis;
        overflow:hidden;
        white-space: nowrap;
      }
      .desc {
        overflow:hidden;
        max-height: 6em;
      }
      .date {
        position: absolute;
        bottom: 15px;
        text-align: right;
        .fa {
          margin: 0 15px;
        }
      }

    }

    .edit-block form, .delete-block form {
      display: none;
    }

    &.processing {
      &:before {
        display: block;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0,0,0,.7);
        z-index: 95;
        position: absolute;
        border-radius: 4px;
      }
      &:after {
        content: attr(data-text);
        position: absolute;
        z-index: 95;
        color: white;
        left: 50%;
        vertical-align: middle;
        display: inline;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
  li {
    footer section div.setoptions a {
      background: #cc4619;
      font-size: 13px;
      font-weight: 600;
      color: #fff;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      line-height: 30px;
      border-radius: 3px;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.15) inset;
      padding: 0;
      margin: 0 0 10px 0;
      position: relative;
      display: block;
      cursor: pointer;
      &:hover {
        background: #444;
      }
    }
    .badge {
      display: none;
      position: absolute;
      top: 0;
      left: 234px;
      width: 32px;
      height: 32px;
      border-radius: 0;
      z-index: 94;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
      line-height: 34px;
      text-align: center;
      font-size: 16px;
      cursor: default;
      background: linear-gradient(to bottom, #fff 0%, #eee 100%);
      color: #444;
      &:before {
        content: 'P';
      }
      &.access2, &.access3 {
        display: block;
      }
      &.type1, &.type2 {
        left: 25px;
        display: block;
      }
      &.type1:before {
        content: 'V';
      }
      &.type2:before {
        content: 'I';
      }
    }
  }
}