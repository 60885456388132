//$fanclubs-thestore_box-bg_color:  #{$popup-bg-color};
//$fanclubs-thestore_box-color:  #{$popup-color};
$fanclubs-dropzone-border: 2px #fff dashed;
$fanclubs-dropzone-color:  #fff;

$fanclubs-dropzone-bg_color: rgba(0, 0, 0, .3);
$fanclubs-dropzone-hover-bg_color: rgba(0, 0, 0, .2);

$fanclubs-input-color:  #fff;
$fanclubs-input-bg_color:  rgba(0, 0, 0, .3);
$fanclubs-input-border_radius:  4px;
$fanclubs-input-border:  1px solid;
$fanclubs-input-box_shadow:  none;

$fanclubs-input_focus-border_color:  #999;
//$fanclubs-input_focus-outline_color:  #{tune-color($input-color,5%)};
$fanclubs-input_focus-bg_color:  rgba(0, 0, 0, .2);
$fanclubs-input_focus-color:  $fanclubs-input-color;
$fanclubs-input_focus-box_shadow:  inset 0 1px 1px rgba(0, 0, 0, 0.2);
$fanclubs-option-bg_color:  #fff;
$fanclubs-footer_meta-bg_color: rgba(0,0,0,.8);

body {
//--funclubs-thestore_box-bg_color: #{$fanclubs-thestore_box-bg_color};
//--funclubs-thestore_box-color: #{$fanclubs-thestore_box-color};
--dropzone-border: #{$fanclubs-dropzone-border};
--dropzone-color:  #{$fanclubs-dropzone-color};
--dropzone-bg_color: #{$fanclubs-dropzone-bg_color};
--dropzone_hover-bg_color: #{$fanclubs-dropzone-hover-bg_color};
--funclubs-input-color: #{$fanclubs-input-color};
--funclubs-input-bg_color: #{$fanclubs-input-bg_color};
--funclubs-input-border_radius: #{$fanclubs-input-border_radius};
--funclubs-input-border: #{$fanclubs-input-border};
--funclubs-input-box_shadow: #{$fanclubs-input-box_shadow};
--funclubs-input_focus-border_color: #{$fanclubs-input_focus-border_color};
//--funclubs-input_focus-outline_color: #{$fanclubs-input_focus-outline_color};
--funclubs-input_focus-bg_color: #{$fanclubs-input_focus-bg_color};
--funclubs-input_focus-color: #{$fanclubs-input_focus-color};
--funclubs-input_focus-box_shadow: #{$fanclubs-input_focus-box_shadow};
--funclubs-option-bg_color: #{$fanclubs-option-bg_color};
--fanclub-footer_meta-bg_color: #{$fanclubs-footer_meta-bg_color};

}


.fanclub-header-links {
  margin-bottom: 30px;

  a {

    &.active {
      background-color: unset;
    }

  }

}

form.fanclub-form {
  text-align: start;

  .form-control.numeric {
    text-align: right;
    padding-right: 5px;
  }

}

.performer-fanclub-page {
  .dropzone {
    min-height: 100px;
    height: auto;
    border: var(--dropzone-border, 2px white dashed);
    color: var(--dropzone-color, white);
    background-color: var(--dropzone-bg_color, rgba(0,0,0,0.1));
  }


  .big-thumbnail {
    max-width: 100%;
    width: 200px;
    height: 120px;
    object-fit: cover;
    object-position: center;
  }

  ul.diary-list, ul.store-list  {
    list-style: none;
    margin: 30px auto;

    li {
      &:nth-child(2n-1){
        padding-left: 0;
      }
      &:nth-child(2n) {
        padding-right: 0;
      }
    }

    .entry {
      background-color: rgba(0,0,0,0.1);
      display: flex;
      padding: 15px;
      margin-bottom: 30px;

      .small-thumbnail {
        width: 3em;
        height: 3em;
        background-size: cover;
      }

    }

  }

  .pagerfooter > tbody:first-child > tr:nth-child(3) > td:first-child {
    padding-top: 20px;
  }

  //forms elements
  //input.text, input.password, textarea, select, .file,
  //input.form-control, select.form-control, textarea.form-control {
  //  margin: 0;
  //  padding: 0 0 0 5px;
  //  box-sizing: border-box;
  //
  //  color: var(--funclubs-input-color, #000);
  //  background: var(--funclubs-input-bg_color, #fff);
  //  border-radius: var(--funclubs-input-border_radius, 5px);
  //  border: var(--funclubs-input-border, 1px solid #9e9e9e);
  //  box-shadow: var(--funclubs-input-box_shadow, inset 0 1px 1px rgba(0, 0, 0, 0.2));
  //
  //  &:focus {
  //    border-color: var(--funclubs-input_focus-border_color, #9e9e9e);
  //    outline-color: var(--funclubs-input_focus-outline_color, #9e9e9e);
  //    background: var(--funclubs-input_focus-bg_color, #fff);
  //    box-shadow: var(--funclubs-input_focus-box_shadow, inset 0 1px 1px rgba(0, 0, 0, 0.2));
  //  }
  //}



}

.performer-fanclub-page,
.swal2-contentwrapper {

  input[type="number"] {
    -moz-appearance:textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

}

/* SETTINGS PAGE */

.fanclub-settings {

  .fanclub-image-background {
    max-width: 100%;
    margin: 20px 0;
  }

}

/* PHOTOSETS PAGE */

.fanclub-photosets {}

/* DIARIES PAGE */

.fanclub-diaries {}

/* SHOWS PAGE */

.fanclub-shows {

  .sorting .search {
    input[type=text] {
      border: none !important;
      padding: 10px;
      min-width: 215px;
    }
    display: flex;
    justify-content: flex-end;
  }

}

@media screen and (max-width: 768px) {
  ul.diary-list, ul.store-list {
    li {
      padding: 0;
    }
  }
}

.customer-fanclub-page {
  margin-top: 50px;

  .fanclub-header {
    display: flex;
    flex-direction: column;

    .fanclub-header-video, .fanclub-header-description {
      width: 100%;
      max-width: 1200px;
      margin: 25px auto 0 auto;
    }
  }

  .page {
    /*display: none;*/

    &.page-latest {
      display: block;

/*      .media-list {
        display: none;
      }*/
    }

    &.page-purchased {
      display: block;

      .fanclub-header-links { padding: 0 15px }

      .no-purchases {
        display: none;
        text-align: center;
        width: 100%;
      }

      .media-list {
        max-width: 100%;
      }
    }

    &.page-purchased + .page-content {
      .media-list .nav a.view-all { display: none }
    }

  }

  .media-list, .diary-entry {
    max-width: 1200px;
    margin: 0 auto;

    .nav {
      margin: 20px 0;
      a {cursor: pointer}
    }

    h2,h4 {
      text-align: left;
    }
  }

  .diary-entry {
    img.diary-image {
      float: left;
      margin: 25px 15px 15px 0;
    }
    .diary-title {
      font-weight: bolder;
    }
  }


}

.form-group img.ui-datepicker-trigger {
  right: 20px;
  top: 5px;
  position: absolute;
}

.swal2-popup .swal2-buttonswrapper {
  align-items: stretch;
}

.blured-content {
  filter: blur(10px);
}

.fanclub-items-list {
  header {
    width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h4 {
      display: block;
      margin: 20px;
    }
  }

  ul.media-list {
    max-width: 1200px;
    display: flex;
    flex-wrap: nowrap;

    &.expanded {
      flex-wrap: wrap;
    }
  }
}