.thestore-purchase {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15098;
  overflow-y: scroll;
  &-close {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15094;
  }
}

.thestore-purchasebox,
.thestore-shippingbox,
.thestore-confirm {
  background-color: var(--funclubs-thestore_box-bg_color, #fff);
  color: var(--funclubs-thestore_box-color, #000);
  text-align: center;
  border-radius: 10px;
  margin: 35px auto 0 auto;
  position: relative;
  z-index: 15095;
  * {
    box-sizing: border-box;
  }
}

.thestore-purchase .thestore-purchasebox {
  max-width: 500px;
  padding: 30px;
  & > img {
    width: auto;
    height: 203px;
    border-radius: 300px;
    margin: 0 auto 25px auto;
    position: relative;
    display: inline-block;
  }
  button[type="submit"] {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 10px 18px;
    margin-top: 15px;
    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }
  & > p {
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    margin-top: 25px;
    position: relative;
  }

}

.thestore-shippingbox {
  max-width: 700px;
  overflow: hidden;
  & > aside {
    width: 220px;
    padding: 15px 30px 0 30px;
    position: relative;
    float: left;
    background-color: rgba(0, 0, 0, 0.2);
    .h1 {
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 25px;
      position: relative;
    }
    & > h2 {
      font-size: 14px;
      font-weight: 500;
      //color: #444;
      text-align: left;
      position: relative;
    }
    & > h3 {
      font-size: 12px;
      font-weight: 400;
      //color: #888;
      text-align: left;
      margin-bottom: 6px;
      position: relative;
    }
    & > h4 {
      font-size: 14px;
      font-weight: 500;
      //color: #2ca8d2;
      text-align: left;
      margin-bottom: 10px;
      position: relative;
    }
    & > p {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 10px;
      position: relative;
    }
    & a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  & > section {
    width: 360px;
    padding: 15px 30px 30px 30px;
    position: relative;
    flex: 1 1 auto;
    .h1 {
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      letter-spacing: -1px;
      border-bottom: 1px solid #eee;
      padding-bottom: 25px;
      position: relative;
    }
    & > form {
      text-align: center;
      position: relative;
      & > label {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        padding: 10px 0;
        position: relative;
        display: block;
      }
    }
  }
}

.thestore-confirm {
  max-width: 500px;
  padding: 30px;
  .h1 {
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 25px;
    position: relative;
  }
  > p {
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 25px;
    position: relative;
  }
  button[type="submit"] {
    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    padding: 10px 20px;
    position: relative;
    display: inline-block;
    &:hover {
      text-decoration: none;
    }
  }
}

.shippingfield {

  width: 100%;
  //height: 40px;
  ////font-family: 'Roboto', Helvetica, Arial, sans-serif;
  //font-size: 18px;
  //font-weight: 300;
  //
  //text-align: center;
  //border: 0;
  //border-radius: 4px;
  //margin: 0 0 15px 0;
  //padding: 0;
  //position: relative;
}

textarea.shippingfield {
  height: 60px;
  max-width: 100%;
}

.hidden {
  display: none !important;
}

.shippingsubmit {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  //background: #2ca8d2;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  //font-size: 24px;
  font-weight: 300;
  //color: #fff;
  text-align: center;
  border: 0;
  border-radius: 4px;
  padding: 10px 20px;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:hover {
    //background: #666;
    //color: #aaa;
  }
}

#confirmFormStore .result {
  font-size: 16px;
}

.thestore-shippingbox {
  display: flex;
}

.thestore-close-button {
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 16px;
  z-index: 2;
  cursor: pointer;
}

@media screen and (max-width: 725px) {
  .thestore-close-button {
    color: #000;
  }

  .thestore-shippingbox,
  .thestore-purchasebox,
  .thestore-confirm {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    overflow-y: scroll;
  }

  .thestore-shippingbox {
    flex-direction: column;
  }
  .thestore-shippingbox > aside,
  .thestore-shippingbox > section {
    width: 100%;
  }
}
